export const carouselPic = [
  {
    altText: "1st Pic",
    src: "../images/Me.jpg",
  },
  {
    altText: "2nd Pic",
    src: "../images/honeymoon.jpg",
  },
  {
    altText: "3rd Pic",
    src: "../images/usmcme2.jpg",
  },
  {
    altText: "4thd Pic",
    src: "../images/danyWedding.jpg",
  },
  {
    altText: "4thd Pic",
    src: "../images/Me2.jpg",
  },
];
